<!-- Finder particular de Miscelanea -->

<template>
  <div class="misc_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">
      
        <!-- Cabecera -->
          <div class="cabecera" v-if="Entorno.header.header">
            <base_Header 
              :componenteTipo="componenteTipo"
              :Entorno="Entorno.header"
              @onEvent="event_Header">
            </base_Header>
          </div>

        <!-- Contenido -->
        <div class="contenedor columna" style="width:800px"> 

          <!-- Filtro de Busqueda -->
          <div class="conflex">
            <v-text-field          
              style="flex: 1 1 70%"
              v-bind="$input"
              v-model="schema.ctrls.name.value"
              :label="schema.ctrls.name.label"
              @keypress.13="buscarExec('buscarMisc')">                
            </v-text-field>
            
            <v-btn v-bind="$cfg.btn.busca" @click="buscarExec('buscarMisc')">
              <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
            </v-btn> 

            <v-checkbox
              class="ml-2"
              v-bind="$checkbox"
              v-model="schema.ctrls.bajas.value"
              :label="schema.ctrls.bajas.label">
            </v-checkbox>

          </div>

          <div style="display:flex">
            <v-select         
              style="flex: 1 1 35%"
              v-bind="$select"
              v-model="schema.ctrls.tipo.value"
              :label="schema.ctrls.tipo.label"
              :items="tipo_misc"
              item-value="id"
              item-text="name">                
            </v-select>

            <ctrlfinder 
              style="flex: 1 1 35%"
              finderName="atlas_F" 
              :schema="schema.ctrls.loc"> 
            </ctrlfinder>

            <ctrlfinder
              v-if="app==1"     
              style="flex: 1 1 30%"      
              finderName="cias_F" 
              :schema="schema.ctrls.cia_id"> 
            </ctrlfinder>
            
          </div>          

          <!-- Grid -->
          <base_Fgrid 
            :padre="stName" 
            :Entorno="Entorno" 
            dense
            @onEvent="event_Grid">
            
            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">         
              <div style="padding:10px 0 0 10px;">
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                </btracombi>
              </div>
            </template>

            <!-- Slot campo acciones del Grid -->
            <template v-slot:acciones="{ Sitem }">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </template>
          </base_Fgrid>
        </div>
      </v-sheet>

      <!-- Mto como ventana modal -->      
      <v-dialog
        v-model="modal">
          <div class="centrado">             
            <component                 
                :is="component_M"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>  

    </div>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const misc_M = () => plugs.groute("misc_M.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Header,  base_Fgrid, btracombi, ctrlfinder, misc_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {        
        schema:null,
        api:'misc_F',
        stName:'stFmisc',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,

        headers: {}, 
        tipo_misc:[]      
      };
    },

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Miscelanea";  

        this.headers = {
          header: [
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Nombre", value: "name", width: "40%" },
            { text: "Tipo", value: "tipo", width: "20%" },
            { text: "Atlas", value: "atlas", width: "30%" }            
          ],
        };

        this.Entorno.grid.headers = this.set_headers();

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'misc_M';

        // items adicionales
        this.tipo_misc= this.$store.state.datos_iniciales.misctipo;
        this.tipo_misc.unshift({ id:"0", name:"(Selecciona Tipo)"});
      }, 


    }
  };
</script>
